<template>
  <div>
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <el-backtop target=".el-main"></el-backtop>

    <div>
      <el-button type="primary" @click="handleAddAgent">添加代理</el-button>

      <el-dialog title="添加代理" :visible.sync="dialogFormVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item
            label="请选择省份"
            :label-width="formLabelWidth"
            prop="provinceValue"
          >
            <el-select
              @visible-change="handleSelectProvince"
              v-model="ruleForm.provinceValue"
              value-key="id"
              filterable
              placeholder="请选择省份"
              clearable
            >
              <el-option
                v-for="item in provinceOptions"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="代理人姓名"
            :label-width="formLabelWidth"
            prop="realName"
          >
            <el-input v-model="ruleForm.realName"></el-input>
          </el-form-item>
          <el-form-item
            label="奖励发放账号"
            :label-width="formLabelWidth"
            prop="agentPhone"
          >
            <el-input v-model="ruleForm.agentPhone"></el-input>
          </el-form-item>
          <el-form-item
            label="审核后台账号"
            :label-width="formLabelWidth"
            prop="loginPhone"
            style="margin-bottom: 13px"
          >
            <el-input v-model="ruleForm.loginPhone"></el-input>
          </el-form-item>
          <div class="ps_pass">
            提示：审核后台账号（初始密码：手机号后六位）
          </div>
          <el-form-item
            label="备注"
            :label-width="formLabelWidth"
            prop="remark"
          >
            <el-input v-model="ruleForm.remark"></el-input>
          </el-form-item>
          <div class="el_form_item_b_btn">
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >添 加</el-button
              >
              <el-button @click="dialogFormVisible = false">取 消</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="代理人姓名" width="130">
        </el-table-column>
        <el-table-column prop="agentPhone" label="奖励发放账号" width="130">
        </el-table-column>
        <el-table-column prop="loginPhone" label="审核后台账号" width="130">
        </el-table-column>
        <el-table-column prop="agentTime" label="代理时间" width="180">
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="180">
        </el-table-column>
        <el-table-column prop="country" label="国家" width="80">
        </el-table-column>
        <el-table-column prop="province" label="省份"> </el-table-column>
        <el-table-column prop="city" label="城市"> </el-table-column>
        <el-table-column prop="amount" label="累计收益" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.amount }}</p>
            <el-button
              type="text"
              size="small"
              @click="handleViewDetails(scope.row)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: #f56c6c"
              @click="handleDelete(scope.row)"
              >移 除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="amount_dialog">
        <el-dialog title="累计收益-查看明细" :visible.sync="dialogAmountShow">
          <el-table :data="gridData">
            <el-table-column
              property="amount"
              label="金额"
              width="150"
            ></el-table-column>
            <el-table-column property="detail" label="详情"></el-table-column>
            <el-table-column property="remark" label="备注"></el-table-column>
            <el-table-column
              property="createTime"
              label="时间"
            ></el-table-column>
          </el-table>
          <Pagination
            v-show="amount.pageLength >= 0"
            :total="amount.pageLength"
            :limit.sync="amount.pageSize"
            @pagination="handlePageChangeAmount"
            :page-sizes="amount.pageSizes"
          />
        </el-dialog>
      </div>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import AlCascader from "@/components/Cascader/cascader.vue";
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: { AlCascader, Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    var validatePhone = (rule, value, callback) => {
      var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("账号不可为空！"));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error("手机号格式有误，请输入正确手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "代理/运营",
        },
        {
          name: "省运营",
        },
      ], // 面包屑数据

      tableData: [],
      gridData: [], // 累计收益-查看明细

      provinceOptions: [],

      dialogFormVisible: false,
      dialogAmountShow: false,

      ruleForm: {
        provinceValue: "",
        realName: "",
        agentPhone: "",
        loginPhone: "",
        remark: "",
      },
      rules: {
        provinceValue: [
          { required: true, message: "请选择省份", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入代理人姓名", trigger: "blur" },
        ],
        agentPhone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        loginPhone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
      },
      formLabelWidth: "120px",

      detailRowUserId: "", // 累计收益-查看明细-userId

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200],

      amount: {
        // 分页数据
        currentPage: 1, // 当前页
        pageLength: 0,
        pageSize: 10, //要传过去的数据 每页多少条数据
        pageSizes: [10, 20, 50, 100, 200],
      },
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getAllAgentInfo,
      method: "POST",
      params: JSON.stringify({
        pageNum: "",
        pageSize: "",
        areaLevel: 2, // 省级
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.data.list) {
          this.tableData = res.data.data.list;

          res.data.data.list.map((item) => {
            item.agentTime = format_time_date(item.agentTime);
            item.unagentTime = format_time_date(item.unagentTime);
          });
        }
        this.pageLength = res.data.data.total;
        this.pageSize = res.data.data.pageSize;

        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    handleSelectProvince(e) {
      if (e) {
        const opt = {
          url: reqUrl.getProvinceCity,
          method: "POST",
          params: JSON.stringify({
            pid: "0",
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.data) {
              this.provinceOptions = res.data.data;
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      }
    },

    handlePageChange(data) {
      const opt = {
        url: reqUrl.getAllAgentInfo,
        method: "POST",
        params: JSON.stringify({
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          areaLevel: 2, // 省级
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.list) {
            this.tableData = res.data.data.list;

            res.data.data.list.map((item) => {
              item.agentTime = format_time_date(item.agentTime);
              item.unagentTime = format_time_date(item.unagentTime);
            });
          }
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = data.pageIndex;

          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleAddAgent() {
      this.dialogFormVisible = true;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();
          const opt = {
            url: reqUrl.insertCityAgent,
            method: "POST",
            params: JSON.stringify({
              province: this.ruleForm.provinceValue.name,
              realName: this.ruleForm.realName,
              agentPhone: this.ruleForm.agentPhone,
              loginPhone: this.ruleForm.loginPhone,
              remark: this.ruleForm.remark,
              operator: localStorage.getItem("userName"),
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.dialogFormVisible = false;
                this.$message({
                  message: "添加代理成功",
                  type: "success",
                });

                this.refresh();
              }
              hideLoading();

              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        } else {
          this.$message.error("添加失败，请检查输入信息是否有误");
          return false;
        }
      });
    },

    handleDelete(row) {
      this.$prompt("请输入备注", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const opt = {
            url: reqUrl.deleteCityAgent,
            method: "POST",
            params: JSON.stringify({
              userId: row.userId,
              remark: value,
              operator: localStorage.getItem("userName"),
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.refresh();
              }
              hideLoading();

              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    handleViewDetails(row) {
      this.dialogAmountShow = true;
      this.detailRowUserId = row.userId;

      const opt = {
        url: reqUrl.getCityAgentRewardLog,
        method: "POST",
        params: JSON.stringify({
          userId: row.userId,
          pageNum: 1,
          pageSize: 10,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.list) {
            this.gridData = res.data.data.list;
            res.data.data.list.map((item) => {
              item.createTime = format_time_date(item.createTime);
            });
          }

          this.amount.pageLength = res.data.data.total;
          this.amount.pageSize = res.data.data.pageSize;

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handlePageChangeAmount(data) {
      showLoading();
      const opt = {
        url: reqUrl.getCityAgentRewardLog,
        method: "POST",
        params: JSON.stringify({
          userId: this.detailRowUserId,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.data.list) {
            this.gridData = res.data.data.list;
            res.data.data.list.map((item) => {
              item.createTime = format_time_date(item.createTime);
            });
            hideLoading();
          }

          this.amount.pageLength = res.data.data.total;
          this.amount.pageSize = res.data.data.pageSize;
          this.amount.currentPage = data.pageIndex;
          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.select_province {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;

  p {
    width: 255px;
    margin-left: 15px;
  }
}

.el-input {
  width: 300px;
}

::v-deep .el-dialog {
  width: 463px;
}

.amount_dialog {
  ::v-deep .el-dialog {
    width: 80%;
  }
}

.el_form_item_b_btn {
  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: center;
  }
}

.ps_pass {
  display: flex;
  justify-content: center;
  color: #f56c6c;
  margin-bottom: 20px;
}
</style>
